.container_ {
    position: relative;
    width: 100%;
    max-width: 300px;
    align-items: center;
    justify-content: center;
}


.red-dot {
    z-index: 1000 !important;
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: red;
    border-radius: 50%;
    transform: translateX(-50%);
    top: -2px
}


.slider {
    position: relative;
    width: 100%;
    max-width: 300px;
}

.slider__track,
.slider__range,
.slider__left-value,
.slider__right-value,
.slider__left-value_min,
.slider__right-value_max {
    position: absolute;
}

.slider__track,
.slider__range {
    border-radius: 3px;
    height: 5px;
}

.slider__track {
    background-color: #ced4da;
    width: 100%;
    /*z-index: 1;*/
}


.slider__range {
    background-color: #6691E7;
    z-index: 2;
}

.slider__left-value,
.slider__right-value,
.slider__left-value_min,
.slider__right-value_max {
    color: #000000;
    font-size: 12px;
    margin-top: 10px;
}


.slider__left-value {
    left: 20px;
    top: -35px;
}

.slider__right-value {
    right: 20px;
    top: -35px;
}

.slider__left-value_min {
    left: 6px;
}

.slider__right-value_max {
    right: -4px;
}


/* Removing the default appearance */
.thumb,
.thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    max-width: 300px;
    outline: none;
}

.thumb--left {
    z-index: 3;
}

.thumb--right {
    z-index: 4;
}

/* For Chrome browsers */
.thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}

/* For Firefox browsers */
.thumb::-moz-range-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}
