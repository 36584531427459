.faqs .accordion {
  left: 0;
  width: 400px;
  max-height: 85vh;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 99;
  overflow-y: auto;
  scrollbar-width: thin;
}
.faqs .accordion-mobile {
  display: none;
}
@media (max-width: 1024px) {
  .faqs .accordion {
    display: none;
  }
  .faqs .accordion-mobile {
    display: block;
    width: 100%;
    height: 100%;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    z-index: 99;
    overflow-y: auto;
    scrollbar-width: thin;
  }
}
@media (min-width: 1025px) {
  .faqs .accordion-item {
    margin: 0;
  }
}
.faqs .accordion-item {
  background: linear-gradient(to bottom, #232323, #303030);
  border: 1px solid #343434;
  border-radius: 8px;
  cursor: pointer;
  /* padding: 8px 0; */
}
.faqs .accordion-item a {
  direction: rtl;
  width: 100%;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  color: #d9d9d9;
}
.faqs .accordion-intro-h {
  font-size: 24px;
  font-weight: 500;
  text-align: right;
}
.faqs .accordion-item .accordion-icon {
  color: #d9d9d9;
  width: 10px;
  height: 10px;
  transform: rotate(180);
  transition: transform .3s;
}
.faqs .accordion-item p {
  direction: rtl;
  text-align: right;
  margin-top: 16px;
  font-size: 15px;
  font-weight: 500px;
  color: #d9d9d9;
}