@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);


body, html{
    background-color: #2A2A2A;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden;
}
body{
    overflow-x: auto;
    /* min-width: 2024px !important; */
    /* always show the right side of the page while overflowing */
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scroll-padding: 0 50px;
}
.main {
    width: 100%;
    overflow: hidden;
    /* min-width: 1024px; */
    min-height: 600px;
    /* height: calc(100vh - (3vh)); */
}
.charts {
    /* max-width: 1850px; */
    /* min-width: 1024px; */
    height: calc(100vh - 140px);

    /* margin: auto; */
    /*float: right;*/
}

.advanced-search-dropdown {
    max-height: 600px;
    overflow-y: scroll;
    z-index: 999;
}
.advanced-search-dropdown::-webkit-scrollbar {
    width: 8px;
}

.advanced-search-dropdown::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 10px;
}

.advanced-search-dropdown::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.advanced-search-dropdown::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* Custom Scrollbar for Firefox */
.advanced-search-dropdown {
    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
}

@media (max-width: 1024px) {
    /* Styles */
    .charts .left_chart {
        display: none;
    }
    .charts .right_chart {
        width: 100%;
    }
}
/* Medium large devices (laptops, 1024px and up) */
@media (min-width: 1025px) and (max-width: 1600px) {
    /* Styles */
    .charts .left_chart {
        width: 30%;
    }
    .charts .right_chart {
        width: 70%;
    }
    .related_corps{
        font-size: 13px;
    }
}

/* Large devices (large desktops, 1601px and up) */
@media (min-width: 1601px) {
/* Styles */
    .charts .left_chart {
        width: 45%;
    }
    .charts .right_chart {
        width: 55%;
    }
    .advanced-search-dropdown {
        height: auto;
        max-height: max-content;
        overflow-y: hidden;
    }
}

.main{
    position: relative;
}

/* Extra large devices (large desktops, 1801px and up) */
@media (min-width: 1801px) {
    .charts .left_chart {
        width: 50%;
    }
    .charts .right_chart {
        width: 50%;
    }
}

.table_row:hover,
.table_row:focus,
.table_row:active,
table:hover,
.table_row tr {
    background-color: transparent;
    cursor: pointer;
}
.table_row,
.table_row td {
    padding: .35rem 1rem;
    font-size: 14px;
}
.table_row ~ tr,
.table_row ~ tr td {
    padding-top: .35rem;
    padding-bottom: .35rem;
    padding-left: 1rem;
    font-size: 14px;
}

tr td {
    padding-top: .35rem;
    padding-bottom: .35rem;
    padding-right: 48px;
    font-size: 14px;
}

@media (min-width: 1024px) {
    tr td {
        padding: 0;
        font-size: 11px;
    }
    .table_row,
    .table_row td {
        padding: 0;
        font-size: 11px;
    }
    .table_row ~ tr,
    .table_row ~ tr td {
        padding: 0;
        font-size: 11px;
    }
}

.apexcharts-legend{
    flex-wrap: nowrap !important;
    left:0 !important;

}
.h-400{
    height: 400px !important;
}
.apexcharts-legend-text{
    display: inline-block;
    padding: 5px !important;
    line-height: 1.3 !important;
    width: 100%;
}
span.apexcharts-legend-text {
    color: white !important;
    font-size: 11px !important;
    line-height: 1.5;
    /*margin-right: 20PX;*/
}

input{
    background-color: white;
    color: #2A2A2A;
}
.third .apexcharts-legend {
    justify-content: space-between !important;
    margin-right: 0px;
    gap: 10px;
}
.apexcharts-legend-series {
    border-radius: 3px !important;
    margin: 0 !important;
        /*line-height: 1.3 !important;*/

}
.apexcharts-legend-series{
    margin: 0;
    padding: 0;
}

.third .apexcharts-legend-series .apexcharts-legend-text {
    font-family: 'Open Sans Hebrew', serif !important;
     min-height: 41px;

}

.first .apexcharts-legend-series .apexcharts-legend-text {
    font-family: 'Open Sans Hebrew', serif !important;
}

.second .apexcharts-legend-series .apexcharts-legend-text {
    font-family: 'Open Sans Hebrew', serif !important;
}

td {
    font-family: 'Open Sans Hebrew', serif !important;
    font-size: 11px;
    font-weight: 400;
    /*height: 42.5px !important;*/
}

.heb{
    font-family: 'Open Sans Hebrew', serif !important;
    font-size: 11px !important;
    font-weight: 400;
}

.custom-dropdown {
    position: absolute;
    background: white;
    width: 100%;
    min-width: 200px;
    right: 0;
    top: 110%;
    border-radius: 3px;
    font-weight: 400;
    line-height: 2;
    display: flex;
    flex-direction: column;
}
.custom-dropdown button,
.search-popup .popup-btn {
    background-color: #0075ff;
    border: 1px solid #0075ff;
    /* font-size: 12px; */
}
.custom-dropdown button:hover,
.custom-dropdown button:focus,
.custom-dropdown button:active,
.search-popup .popup-btn:hover,
.search-popup .popup-btn:focus,
.search-popup .popup-btn:active {
    background-color: #0166d8;
    border: 1px solid #0166d8;
}
.custom-dropdown button:disabled,
.search-popup .popup-btn:disabled {
    background-color: #f2f2f2;
    border: 1px solid #f2f2f2;
    color: #5f5c5c;
}

.right_chart .apexcharts-inner{
    /* transform: unset; */
    /* transform: translateY(85px); */
    /* transform: translateX(40px) translateY(85px); */
    /* transform: translateX(0px) translateY(85px) scaleX(1.07); */
    /* transform: translateY(85px) translateX(40px) !important; */
    transform: translateY(85px);
}

.right_chart .sum_market_cap .apexcharts-inner {
    transform: translateY(85px) translateX(0px) scaleX(1.04) !important;
}
  
.right_chart .sum_par_percentage .apexcharts-inner {
    transform: translateY(85px) translateX(10px) scaleX(1.01) !important;
}

.right_chart .sum_par_value .apexcharts-inner {
    transform: translateY(85px) translateX(0px) scaleX(1.05) !important;
}

@media (max-width: 1300px) {
    .right_chart .sum_market_cap .apexcharts-inner {
        transform: translateY(85px) translateX(0px) scaleX(1.055) !important;
    }

    .right_chart .sum_par_percentage .apexcharts-inner {
        transform: translateY(85px) translateX(0px) scaleX(1.04) !important;
    }
    
    .right_chart .sum_par_value .apexcharts-inner {
        transform: translateY(85px) translateX(0px) scaleX(1.06) !important;
    }
}
  
.related_corps{
    /*color: black;*/
    margin: 0 5px;
    padding: 0 5px;
    border-radius: 5px;
}
.related_corps:hover{
    /*color: black;*/
    font-weight: bold;
    background-color: gray;
}



/* Add this styling to your CSS file or style tag */
.top-bar {
    background-color: #2A2A2A; /* Use your preferred background color */
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    z-index: 9999;
}

@media screen and (max-width: 1024px) {
    .top-bar {
        padding: 16px 10px;
    }
}

.logo {
    /* Your logo styling */
}

.nav ul {
    list-style: none;
    display: flex;
    gap: 10px;
}

.nav a {
    text-decoration: none;
    color: white;
}

.right_chart::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.right_chart {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}


