.suggestions-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px; /* Adjust the gap as needed */
}

.sugg-btn {
  text-align: right;
  font-weight: 500;
  margin-bottom: 0px; /* Adjust the margin as needed */
}
.sugg-btn p {
  height: 24px;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #2A2A2A;
}

.new_qr_btn {
  padding: 4px 8px;
  background-color: #eee;
  border-radius: 8px;
  font-weight: 500;
}

.suggestion-columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}


.tooltip {
    position: absolute;
    left: 50%; /* Center horizontally */
    transform: translate(-50%, 35px); /* Adjust to center horizontally */
    background-color: black;
    color: white;
    padding: 5px;
    border-radius: 5px;
    visibility: hidden;
    white-space: nowrap;
    z-index: 2; /* Set a higher z-index */
}

.tooltip-arrow {
    position: absolute;
    bottom: 100%; /* Position at the top of the tooltip */
    left: 50%; /* Center horizontally */
    margin-left: -5px; /* Adjust to center horizontally */
    border-width: 5px;
    border-style: solid;
    border-color:  transparent  transparent black transparent; /* Arrow color */
}

.related_corps:hover .tooltip {
    visibility: visible;
}