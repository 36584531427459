

.search-history {
  display: block;
  overflow-x: auto;
  scrollbar-width: none;
  /* display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  gap: 1rem; 
  grid-template-columns: repeat(3, 1fr); */
}
.search-history > div {
  box-sizing: border-box;
}

.table-zebra td,
.table-zebra th {
  min-width: 150px;
}

.table-zebra td:first-child,
.table-zebra th:first-child {
  min-width: 50px;
}