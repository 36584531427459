.first .apexcharts-legend-series:first-child {
    background: #7F7F7F;
    padding: 5px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    width: 130px;
    text-align: center;

}
.first .apexcharts-legend-series:last-child {
    background:   #84B547;
    padding: 5px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 13px !important;
    overflow: hidden;
    width: 150px;
    text-align: center;
}



/* BOTTOM LEFT CHARTS  */

.second .apexcharts-legend-series:first-child {
    background: #AA1985;
    padding: 5px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 13px !important;
    overflow: hidden;
    width: 150px;
    text-align: center;
}
.second .apexcharts-legend-series:nth-child(2) {
    background:  #2dbda8;
    padding: 5px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 13px !important;
    overflow: hidden;
    width: 150px;
    text-align: center;
}

.second .apexcharts-legend-series:last-child {
    background: #E76D3B;
    padding: 5px;
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 13px !important;
    overflow: hidden;
    width: 150px;
    text-align: center;
}



/* MAIN CHART */
.third .apexcharts-legend-series {
  margin: 0 !important;
}

/* QUARTERLY UPDATES LEGEND STYLES */
.third.quarterly .apexcharts-legend-series:first-child {
    background: #7F7F7F;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.quarterly .apexcharts-legend-series:nth-child(2) {
    background: #84B547;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.quarterly .apexcharts-legend-series:nth-child(3) {
    background: #AA1985;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.quarterly .apexcharts-legend-series:nth-child(4) {
    background: #2dbda8;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.quarterly .apexcharts-legend-series:last-child {
    background: #E76D3B;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}

/* CONTINUOUS UPDATES LEGEND STYLES */
.third.continuous .apexcharts-legend-series:first-child {
    background: #F76565;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.continuous .apexcharts-legend-series:nth-child(2) {
    background: #51AF72;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}
.third.continuous .apexcharts-legend-series:last-child {
  background: #3F81C3;
    /*padding: 5px;*/
    /* display: flex; */
    /* justify-content: center; */
    /* align-items: center; */
    border-radius: 5px;
    font-size: 12px !important;
    overflow: hidden;
    min-width: 100px;
    width: 100%;
    text-align: center;
}


/* F_HOLDER LEGEND STYLES */
.third.f_holder .apexcharts-legend-series:first-child {
  background: #84B547;
  /*padding: 5px;*/
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 5px;
  font-size: 12px !important;
  overflow: hidden;
  min-width: 100px;
  width: 100%;
  text-align: center;
}



.first .apexcharts-legend-series *,
.second .apexcharts-legend-series *,
.third .apexcharts-legend-series *
{
    font-size: 12px !important;
    font-weight: bold !important;
    line-height: 0;
    box-sizing: border-box;
}

.apexcharts-legend.apexcharts-align-left.apx-legend-position-top {
    margin-right: auto !important;
    margin-left: 0 !important;
    position: relative;
    left: 7px !important;
}

.third .apexcharts-legend-series::after{
    display: block;
    color: #fff;
    font-size: 9px;
    font-family: 'Open Sans Hebrew', serif !important;
}




.dropdown-content form .flex{
    margin: 25px 0;
}

.flex.justify-end.items-center.options {
    position: absolute;
    right: 0;
    top: 45px;
    padding-right: 20px;
    z-index: 10;
}
.third .apexcharts-legend {
    top: 0 !important;
    padding:0 !important;

}

select{
    background-color: white;
}

.search-popup {
    position: absolute;
    top: 110%;
    width: 100%;
    left: 0;
    border-radius: 3px;
    z-index: 100;
}

/* TOOLTIP IS ENTERING THE TOP OF THE PAGE */
/* THIS LINE OF CODE PUSHES IT DOWNWARD A BIT */
.apexcharts-tooltip {
  margin-top: 50px;
}

.custom-tooltip {
  min-width: 150px;
  max-width: 200px;
  padding: 10px;
  background-color: #000;
  color: #fff; /* White text color */
  border: 1px solid #2A2A2A; /* Dark border color */
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.tooltip-header {
  align-items: center;
  margin-bottom: 8px;

}

.color-circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

.tooltip-title {
  font-weight: bold;
     margin-left: 4px;
    margin-right: 4px;
}

.tooltip-content {
  display: flex;
  flex-direction: column;
}

.data-point,
.value {
  margin-bottom: 4px;
}

.data-point::before,
.value::before {
  margin-right: 4px;
}


.select-wrapper {
  display: flex;
  
  position: relative;
  display: inline-block;
    /* left:-30px; */
    margin: 0;
    line-height: 20px;
    width: 100%;
    cursor: pointer;
}

.select-wrapper ul li button {
  direction: rtl;
}
.select-wrapper ul li button.active {
  background-color: #2A2A2A;
}

.select-wrapper .dropdown-icon::after {
  content: '\25BC'; /* This is the arrow symbol */
  position: absolute;
  top: 50%;
  left: 10px;
  display: inline-block;
  transform: translateY(-50%);
  pointer-events: none;
}

#selectt {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  box-sizing: border-box;
  width: 150%;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

/* Hide Firefox's default arrow */
#selectt::-moz-focus-inner {
  border: 0;
}



.relative-search-wrapper {
  display: flex;
  position: absolute;
  display: inline-block;
  left: -12px;
  width: 56px;
  margin: 0;
  line-height: 20px;
  cursor: pointer;
  /* margin-right: 20px; */
}

.relative-search-wrapper ul li button {
  direction: rtl;
}
.relative-search-wrapper ul li button.active {
  background-color: #2A2A2A;
}

.relative-search-wrapper .dropdown-icon::after {
  content: '\25BC'; /* This is the arrow symbol */
  /* position: absolute; */
  top: 50%;
  left: 10px;
  display: inline-block;
  /* transform: translateY(-50%); */
  width: 56px;
  height: 24px;
  text-align: center;
  justify-self: center;
  pointer-events: none;
  background-color: #2A2A2A;
}
#selectt {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  box-sizing: border-box;
  width: 150%;
  height: 45px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

}

/* Hide Firefox's default arrow */
#selectt::-moz-focus-inner {
  border: 0;
}



.text_box_color{
    background-color: #2A2A2A;
}

.orange_font{
    color: #e76d3b;
}

.orange_font_border{
    border-color: #e76d3b;
}

.orange_color{
    background-color: #e76d3b;
}

.apexcharts-bar-series {
  /* width: 100%; */
}

/*  */
/* .apexcharts-legend-series {
  position: static !important;
} */