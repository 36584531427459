.contact .accordion {
  width: 450px;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  z-index: 99;
}
.contact .accordion-item {
  background: linear-gradient(to bottom, #232323, #303030);
  border: 1px solid #343434;
  border-radius: 8px;
  margin: 0;
  cursor: pointer;
  /* padding: 8px 0; */
}
.contact .accordion-item a {
  direction: rtl;
  width: 100%;
  text-align: right;
  font-size: 15px;
  font-weight: 500;
  color: #d9d9d9;
}
.contact .accordion-item .accordion-intro-h {
  font-size: 15px;
  font-weight: 500;
  text-align: right;
}
.contact .accordion-item .accordion-icon {
  color: #d9d9d9;
  width: 10px;
  height: 10px;
  transform: rotate(180);
  transition: transform .3s;
}
.contact .accordion-item p {
  direction: rtl;
  text-align: right;
  margin-top: 16px;
  font-size: 15px;
  font-weight: 500px;
  color: #d9d9d9;
}