.policy {
  font-family: 'open sans hebrew';
  grid-row: 1 / -1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin: auto 16px;
  /* height: 100%;
  /* max-height: 94vh; */
  /* overflow: auto;
  padding: 1rem;
  scroll-snap-type: proximity;
  max-width: 80%;
  width: 800px;
  margin: auto;
  border-radius: 1rem;
  margin-bottom: 2rem;
  scrollbar-width: none; */
}
.policy .policy-container {
  background-color: rgb(31, 31, 31);
  padding: 2.5rem;
  overflow-y: auto;
  width: 100%;
  max-width: 650px;
  height: 500px;
  max-height: 700px;
  /* scrollbar-width: none; */
  border-radius: .75rem;
  scrollbar-width: thin;
  scrollbar-color: #888 rgb(31, 31, 31);
}

@media (max-width: 1024px) {
  .policy .policy-container {
    height: calc(100vh - 100px);
    max-height: none;
  }
}


.policy h1 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
}
.policy h2 {
  font-weight: 600;
  font-size: 1rem;
  color: #fff;
  margin-bottom: 1rem;
}
.policy p {
  color: #fff;
  margin-bottom: .5rem;
}

.policy li {
  color: #fff;
  margin-bottom: .5rem;
}
.policy ul, ol {
  list-style-type: auto;
  list-style-position: inside;
}
.policy button {
  margin-top: 2rem;
  width: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
/* Width of the entire scrollbar */
.policy .policy-container::-webkit-scrollbar {
  width: 6px;
  position: absolute
}

/* Track (the area where the scrollbar moves) */
.policy .policy-container::-webkit-scrollbar-track {
  background: rgb(31, 31, 31);
}

/* Handle (the draggable scrolling element) */
.policy .policy-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 6px;
}

/* Handle on hover */
.policy .policy-container::-webkit-scrollbar-thumb:hover {
  background: #555;
}